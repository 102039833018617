import React, { useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ArrowLink from "../../../../svg/arrowlinkblack.svg";

// import MapPoint from "../../../../svg/map-point.svg";
// import Map from "../../../../svg/map.svg";
// import PropTypes from 'prop-types';

const ContactsMap = ({ langToggle, dataContacts }) => {
  let itemEl = React.useRef([]);
  itemEl.current = [];

  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    itemEl.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          delay: 0.3,
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !itemEl.current.includes(el)) {
      itemEl.current.push(el);
    }
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setName(value);
    } else if (name === "email") {
      setEmail(value);
    } else {
      setCheckboxes({
        ...checkboxes,
        [name]: e.target.checked,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name required";
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = " Invalid email format";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("https://form.ar-design.com.ua/server", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, checkboxes }),
      });
      setIsSubmitted(true);

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Ошибка при отправке формы:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  console.log(isSubmitted);
  return (
    <div className="contacts__map">
      {/* <a className="contacts__map-link" href="/" ref={addToRefs}> */}
      {/* <Map className="contacts__map-svg" /> */}
      <div className="contacts__map-info" ref={addToRefs}>
        {isSubmitted === false && (
          <h2 className="text-left pt-4 pb-2">
            {langToggle(
              "Отримати консультацію дизайнера",
              "Получить консультацию дизайнера",
              "Get a designer consultation"
            )}
          </h2>
        )}
        {isSubmitted ? (
          <h2 className="text-center" style={{ color: "green" }}>
            {langToggle(
              "Форму відправлено успішно!",
              "Форма отправлена успешно!",
              "Form submitted successfully!"
            )}
          </h2>
        ) : (
          <div className={isSubmitted ? "d-none" : "d-block"}>
            <h2 className="text-left pb-2 pt-3 title">
              {langToggle(
                "Хочу дизайн інтер'єру",
                "Хочу дизайн интерьера",
                "I want interior design"
              )}
            </h2>
            <ul>
              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="Квартира"
                  checked={checkboxes["Квартира"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                {langToggle("Квартира", "Квартира", "Apartment")}
              </li>
              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="Дом"
                  checked={checkboxes["Дом"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                {langToggle("Будинок", "Дом", "House")}
              </li>
              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="Коммерческое недвижимость"
                  checked={checkboxes["Коммерческое недвижимость"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                {langToggle(
                  "Комерційна нерухомість",
                  "Коммерческое недвижимость",
                  "Commercial real estate"
                )}
              </li>
            </ul>
            <h2 className="text-left pb-2 pt-3 title">
              {langToggle(
                "Площа під дизайн проект",
                "Площадь под дизайн проект",
                "Area for design project"
              )}
            </h2>
            <ul>
              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="30-50 м²"
                  checked={checkboxes["30-50 м²"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                30 - 50 м²
              </li>
              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="50 - 150 м²"
                  checked={checkboxes["50 - 150 м²"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                50 - 150 м²
              </li>

              <li className="d-flex items-center">
                <input
                  type="checkbox"
                  name="больше 150 м²"
                  checked={checkboxes["больше 150 м²"]}
                  onChange={handleChange}
                  className="mr-2"
                />
                {langToggle(
                  "Більше 150 м²",
                  "Больше 150 м²",
                  "More than 150 м²"
                )}
              </li>
            </ul>
            <h2 className="text-left pb-2 py-3 title">
              {langToggle(
                "Хочу консультацію дизайнера інтер'єру",
                "Хочу консультацию дизайнера интерьера",
                "I would like to consult an interior designer"
              )}
            </h2>

            <ul>
              <li className="d-flex items-center pb-4">
                <input
                  type="text"
                  placeholder={langToggle("Ім'я *", "Имя *", "Name *")}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="input"
                />
              </li>
              {errors.name && (
                <p style={{ color: "red" }} className="text-left pb-2">
                  {errors.name}
                </p>
              )}
              <li className="d-flex items-center pb-2">
                <input
                  type="email"
                  placeholder="Email *"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input"
                />
              </li>
              {errors.name && (
                <p style={{ color: "red" }} className="text-left">
                  {errors.email}
                </p>
              )}
            </ul>
            <button onClick={handleSubmit} className="btn">
              <span className="link-title">
                {isSubmitting
                  ? `${langToggle("Вирушає", "Отправляется", "Sent")}...`
                  : `${langToggle("Відправити", "Отправить", "Send")}`}
              </span>
            </button>
            {/* <button
              className="d-flex button-link button-submit"
              onClick={handleSubmit}
            >
              <span className="button-link--wrapper">
                <span className="link-title">
                  {isSubmitting
                    ? `${langToggle("Вирушає", "Отправляется", "Sent")}...`
                    : `${langToggle("Відправити", "Отправить", "Send")}`}
                </span>
                <i className="link-icon">
                  <ArrowLink />
                </i>
              </span>
            </button> */}
          </div>
        )}
        <h2 className={isSubmitted ? "d-none" : "d-block text-left pt-5 title"}>
          {/* {langToggle(
            "Або зв'яжіться з нами за месенджерами",
            "Или свяжитесь с нами по мессенджерам",
            "Get a designer consultation"
          )} */}
        </h2>
        {/* <ul className={isSubmitted ? "d-none" : "d-block form-messanger"}>
          {dataContacts?.messengers?.map((item, index) => {
            return (
              <li className="feedback-messanger__item" key={index}>
                <a
                  href={item?.link}
                  className="feedback-messanger__item-link"
                  target="blank"
                >
                  <span className="messanger-item__link-title">
                    {item?.messenger}
                  </span>
                  <ArrowLink className="messanger-item__link-icon" />
                </a>
              </li>
            );
          })}
        </ul> */}
        {/* <MapPoint className="contacts__map-marker" />
        <p className="contacts__map-text">
          {langToggle(
            "Відкрити на Google Картах",
            "Открыть на Google Картах",
            "View on Google Maps"
          )}
        </p> */}
      </div>
    </div>
  );
};

// ContactsMap.propTypes = {};

// mwmb jplu bnbq uaty

export default ContactsMap;
